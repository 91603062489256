import * as React from "react"
import './AmountInput.css'

const AmountInput = ({ heading, amount, change, unit}) => {
  const handler = (e) => {
    change(e.target.value)
  }
  return (
    <div className="amount-input">
      <h5>{heading}</h5>
      <div>
        <input 
          onChange={handler}
          className="amount-input-field"
          type="number"
          value={amount}
          />
        <span style={{
            marginLeft: "-56px",
            fontSize: "2.5rem"
          }}>{unit}</span>
      </div>
    </div>
  )
}

export default AmountInput;