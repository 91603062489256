import * as React from "react"
import './VisualAmount.css'

const VisualAmount = ({ heading, amount, unit}) => {
  return (
    <div className="visual-amount">
      <h5>{heading}</h5>
      <h3>{amount.toLocaleString('sv-SE')} {unit}</h3>
    </div>
  )
}

export default VisualAmount;